import React from "react"
import Layout from "../components/layout"
import ArticlesComponent from "../components/articles";

import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  query IndexQuery {
    allStrapiCorBlog {
      edges {
        node {
          Content
          updated_at
          strapiId
          published_at
          id
          created_at
          authorName
          Title
          author
          slug
          readTime
          subTitle
          createdAt
          authorUrl
          blogImg {
            childImageSharp{
              fixed(width: 887, height: 500) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

const Banner = () => (
  <div className="banner">
    <img rel="preload" src="/background.jpg" style={{opacity: '50%', width: '100%', maxHeight: '30vh', objectFit: 'cover'}} alt='Chainlink Oracle Reputation Banner'/>
    <div className='content'>
        <h1>
            Illumination
        </h1>
        <h5>
          Reputable content
        </h5>
    </div>
  </div>
)

const IndexPage = () => {
  const data = useStaticQuery(query);
  return (
    <Layout>
      <Banner />
      <ArticlesComponent articles={data.allStrapiCorBlog.edges} />
    </Layout>
  )
}

export default IndexPage;
