import React from "react";
import Card from "./card";

const Articles = ({ articles }) => {
  const sortedArticles = articles.sort((a, b) => new Date(b.node.createdAt) - new Date(a.node.createdAt))
  const titleArticle = sortedArticles[0];
  const remainingArticles = sortedArticles.slice(1, sortedArticles.length)

  return (
    <div class="container" style={{display: 'flex', flexWrap: 'wrap'}}>
      <div class="col-8 card-container">
          <Card
              article={titleArticle}
              key={`${titleArticle.node.Title}`}
          />
      </div>
      {remainingArticles.map((article, index) => {
        return (
        <div class={`card-container col-4`}>
            <Card
                article={article}
                key={`${article.node.Title}`}
            />
            </div>
        )
      })}
    </div>
  );
};

export default Articles;