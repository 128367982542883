import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const Card = ({ article }) => {
  return (
    <Link to={`/article/${article.node.slug}`}>
      <div className="blog-card">
        <div className="content">
          <h4>{article.node.Title}</h4>
          <h5>{article.node.subTitle}</h5>
          <p className="sub-text">{article.node.createdAt} - {article.node.readTime} minute read</p>
          {article.node.blogImg && (
            <Img
              fixed={article.node.blogImg.childImageSharp.fixed}
              imgStyle={{ position: "static", marginTop: 'auto' }}
              />
          )}
        </div>
      </div>
    </Link>
  );
};

export default Card;